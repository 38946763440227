export const descriptions = {
  "Used Tools": "Explore a wide selection of high-quality used power tools, hand tools, and more from leading brands such as DeWalt, Milwaukee, Bosch, and Makita!",
  "Used Power Tools": "Browse a diverse range of used power tools including saws, drills, and more from top brands such as DeWalt, Milwaukee, Bosch, and Makita.",
  "Used Buffers & Polishers": "Find used buffers and polishers for a flawless finish. Featuring top brands from Porter-Cable, DeWalt, Bosch, and Makita.",
  "Used Corded Drills": "Explore used corded drills from trusted brands like DeWalt, Milwaukee, Ryobi, and Makita.",
  "Used Cordless Drills": "Browse our selection of used cordless drills. Featuring top brands from DeWalt, Milwaukee, Bosch, and Makita.",
  "Used Drill Presses": "Shop used drill presses for precision drilling. Featuring top brands from Jet, Delta, Craftsman, and Grizzly.",
  "Used Glue Guns": "Discover used glue guns for your crafting and DIY projects. Featuring top brands from Surebonder, Arrow, Stanley, and Bosch.",
  "Used Grinder": "Find used grinders for sharpening and shaping. Featuring top brands from DeWalt, Makita, Bosch, and Milwaukee.",
  "Used Grout Mixers": "Browse used grout mixers for efficient mixing. Featuring top brands from QEP, Rubi, Marshalltown, and Collomix.",
  "Used Heat Guns": "Shop used heat guns for stripping paint and more. Featuring top brands from Wagner, DeWalt, Bosch, and Milwaukee.",
  "Used Impact Wrenches": "Find used impact wrenches for heavy-duty jobs. Featuring top brands from Ingersoll Rand, DeWalt, Milwaukee, and Makita.",
  "Used Impact Driver": "Explore used impact drivers for powerful driving. Featuring top brands from DeWalt, Milwaukee, Bosch, and Makita.",
  "Used Lathes": "Discover used lathes for precision woodworking. Featuring top brands from Jet, Grizzly, Shop Fox, and Nova.",
  "Used Nail & Staple Guns": "Shop used nail and staple guns. Featuring top brands from Bostitch, DeWalt, Arrow, and Senco.",
  "Used Oscilating Tools": "Find used oscillating tools for versatile tasks. Featuring top brands from Fein, Bosch, Dremel, and Rockwell.",
  "Used Planers": "Browse used planers for woodworking projects. Featuring top brands from DeWalt, Makita, Bosch, and Delta.",
  "Used Rotary Tools": "Discover used rotary tools for precision cutting and more. Featuring top brands from Dremel, Bosch, DeWalt, and Makita.",
  "Used Routers & Joiners": "Find used routers and joiners for woodworking. Featuring top brands from Bosch, DeWalt, Makita, and Porter-Cable.",
  "Used Sanders": "Explore used sanders for smooth finishes. Featuring top brands from Festool, DeWalt, Makita, and Bosch.",
  "Used Power-Actuated Tool": "Shop used power-actuated tools for fastening. Featuring top brands from Ramset, Hilti, Simpson Strong-Tie, and Powers Fasteners.",
  "Used Power Saws": "Browse used power saws for cutting precision. Featuring top brands from DeWalt, Milwaukee, Bosch, and Makita.",
  "Used Screw Guns & Screwdrivers": "Find used screw guns and drivers. Featuring top brands from DeWalt, Milwaukee, Bosch, and Makita.",
  "Used Welding & Soldering Tools": "Discover used welding and soldering tools. Featuring top brands from Lincoln Electric, Miller, Hobart, and ESAB.",
  "Used Winches": "Explore used winches for lifting and pulling. Featuring top brands from Warn, Smittybilt, Superwinch, and Mile Marker.",
  "Used Outdoor Power Equipment": "Explore a wide selection of high-quality used chainsaws, weed-wackers, blowers and more from leading brands such as Craftsman, Black & Decker, and Stihl!",
  "Used Chainsaws": "Shop used chainsaws for cutting tasks. Featuring top brands from Stihl, Husqvarna, Echo, and Makita.",
  "Used Chippers, Shreaders & Mulchers": "Discover used chippers, shredders, and mulchers. Featuring top brands from Patriot Products, Power King, Earthquake, and Troy-Bilt.",
  "Used Edgers": "Find used lawn edgers to fine-tune your lawn. Featuring top brands from Echo, Black+Decker, WORX, and Greenworks.",
  "Used Generators": "Browse used generators for power backup. Featuring top brands from Honda, Generac, Champion, and Westinghouse.",
  "Used Grass Shears": "Discover used grass shears for lawn care. Featuring top brands from Fiskars, Gardena, Bahco, and Corona.",
  "Used Hedge Trimmers": "Shop used hedge trimmers for neat hedges. Featuring top brands from Stihl, Black+Decker, Husqvarna, and Echo.",
  "Used Hole Diggers & Augers": "Find used hole diggers and augers. Featuring top brands from Earthquake, General Equipment, ECHO, and Southland.",
  "Used Lawn Mower": "Explore used lawn mowers for a well-manicured lawn. Featuring top brands from Honda, Toro, Husqvarna, and Craftsman.",
  "Used Leaf Blower": "Discover used leaf blowers for yard cleanup. Featuring top brands from Stihl, EGO, Husqvarna, and Greenworks.",
  "Used Log Splitter": "Find used log splitters for firewood preparation. Featuring top brands from Boss Industrial, Champion, Dirty Hand Tools, and NorthStar.",
  "Used Pressure Washer": "Shop used pressure washers for cleaning tasks. Featuring top brands from Karcher, Ryobi, Sun Joe, and Simpson.",
  "Used Snow Blower": "Browse used snow blowers for snow removal. Featuring top brands from Honda, Toro, Ariens, and Troy-Bilt.",
  "Used String Trimmers": "Find used string trimmers for trimming tasks. Featuring top brands from Stihl, Husqvarna, Echo, and Black+Decker.",
  "Used Sweepers": "Discover used sweepers for cleaning paths and driveways. Featuring top brands from Craftsman, Agri-Fab, Brinly-Hardy, and Precision Products.",
  "Used Shears & Nibblers": "Shop used shears and nibblers. Featuring top brands from Milwaukee, Makita, DeWalt, and Bosch.",
  "Used Tillers": "Explore used tillers for garden cultivation. Featuring top brands from Honda, Troy-Bilt, Earthquake, and Mantis.",
  "Used Hand Tools": "Explore a wide selection of high-quality used hammers, wrenches, saws and more from leading brands like Stanley, Craftsman, and Klein Tools.",
  "Used Caulking & Sealant Guns": "Discover used caulking and sealant guns. Featuring top brands from Dripless, Albion, Newborn, and Cox.",
  "Used Chisels": "Browse used chisels for woodworking and more. Featuring top brands from Stanley, Irwin, Narex, and Two Cherries.",
  "Used Champs & Vises": "Shop used clamps and vises for securing workpieces. Featuring top brands from Bessey, Irwin, Wilton, and Yost.",
  "Used Concrete Tools": "Find used concrete tools for construction projects. Featuring top brands from Marshalltown, Kraft Tool Co., Bon Tool, and Goldblatt.",
  "Used Cutting Tools": "Discover used cutting tools for precise cutting. Featuring top brands from Lenox, Milwaukee, Bosch, and Klein Tools.",
  "Used Files": "Explore used files for smoothing and shaping. Featuring top brands from Nicholson, Simonds, Bahco, and Grobet.",
  "Used Hammers & Mallets": "Shop used hammers and mallets for various tasks. Featuring top brands from Estwing, Vaughan, Stanley, and Dead On Tools.",
  "Used Hand Tool Sets": "Find used hand tool sets for comprehensive toolkits. Featuring top brands from Craftsman, Stanley, Kobalt, and Crescent.",
  "Used Knives & Blades": "Discover used knives and blades for cutting tasks. Featuring top brands from Gerber, Benchmade, Spyderco, and Kershaw.",
  "Used Planes": "Browse used planes for woodworking precision. Featuring top brands from Stanley, Veritas, Lie-Nielsen, and WoodRiver.",
  "Used Pliers": "Shop used pliers for gripping and bending. Featuring top brands from Knipex, Channellock, Klein Tools, and Irwin.",
  "Used Punches": "Find used punches for hole punching and more. Featuring top brands from Starrett, General Tools, Mayhew, and Craftsman.",
  "Used Putty Knives & Scrapers": "Discover used putty knives and scrapers. Featuring top brands from Hyde Tools, Warner, Red Devil, and DAP.",
  "Used Rivet Tools": "Explore used rivet tools for fastening. Featuring top brands from Marson, Arrow Fastener, Astro Pneumatic Tool, and Chicago Pneumatic.",
  "Used Saws": "Find used saws for cutting tasks. Featuring top brands from Stanley, Vaughan, Gyokucho, and Silky Saws.",
  "Used Screwdriver & Nutdrivers": "Shop used screwdrivers and nutdrivers. Featuring top brands from Wiha, Wera, Klein Tools, and Stanley.",
  "Used Shears & Snips": "Discover used shears and snips for cutting. Featuring top brands from Wiss, Midwest Snips, Malco, and Kett.",
  "Used Slab & Brick Lifters": "Find used slab and brick lifters. Featuring top brands from Bon Tool, Kraft Tool Co., and QLT by Marshalltown.",
  "Used Staplers": "Explore used staplers for fastening. Featuring top brands from Arrow Fastener, Bostitch, Stanley, and Swingline.",
  "Used Taps & Dies": "Discover used taps and dies for threading tasks. Featuring top brands from Irwin Hanson, Greenfield, Vermont American, and Ridgid.",
  "Used Trowels": "Find used trowels for masonry and more. Featuring top brands from Marshalltown, QLT by Marshalltown, Bon Tool, and Kraft Tool Co.",
  "Used Winches": "Shop used winches for lifting and pulling. Featuring top brands from Warn, Smittybilt, Superwinch, and Mile Marker.",
  "Used Wire Brushes": "Discover used wire brushes for cleaning and polishing. Featuring top brands from Osborn, Weiler, Forney, and Vermont American.",
  "Used Wrenches": "Find used wrenches for tightening and loosening. Featuring top brands from GearWrench, Craftsman, Proto, and Snap-on.",
  "Used Tool Storage": "Explore used tool storage solutions. Featuring top brands from Black+Decker, Craftsman, Kobalt, and Gladiator.",
  "Used Tool Boxes": "Discover used tool boxes for convenient storage. Featuring top brands from Stanley, Dewalt, Milwaukee, and Plano.",
  "Used Tool Bags, Belts & Pouches": "Shop used tool bags, belts, and pouches. Featuring top brands from CLC Custom Leathercraft, Klein Tools, Veto Pro Pac, and Occidental Leather.",
  "Used Shelving & Racking Systems": "Find used shelving and racking systems. Featuring top brands from Edsal, Gladiator, Husky, and Muscle Rack.",
  "Used Tool Cabinets": "Discover used tool cabinets for organized storage. Featuring top brands from Craftsman, Husky, Kennedy, and Lista.",
  "Used Organizers": "Explore used organizers for efficient storage. Featuring top brands from Akro-Mils, Stanley, Plano, and Husky.",
  "Used Wall Racks": "Find used wall racks for easy tool access. Featuring top brands from Crawford, Rubbermaid, Gladiator, and Flow Wall.",
  "Vintage Tools": "Discover vintage tools with timeless quality. Collectible pieces from Stanley, Disston, Millers Falls, and Keen Kutter.",
  "Used Power Tool Accessories": "Shop used power tool accessories. Featuring top brands from Bosch, DeWalt, Makita, and Milwaukee.",
  "Used Metalworking Tools": "Explore used metalworking tools. Featuring top brands from Jet, Grizzly, Baileigh, and Shop Fox.",
  "Used Woodworking Tools": "Discover used woodworking tools. Featuring top brands from Festool, Powermatic, SawStop, and Laguna Tools.",
  "Used Air Tools & Equipment": "Find used air tools and equipment. Featuring top brands from Ingersoll Rand, Campbell Hausfeld, Rolair, and California Air Tools.",
  "Used Specialty Tools": "Explore used specialty tools for unique tasks. Featuring top brands from Klein Tools, Fluke, Greenlee, and Ridgid.",
  "Used Batteries": "Shop used batteries for power tools and more. Featuring top brands from DeWalt, Milwaukee, Makita, and Bosch.",
  "Used Speakers & Radios": "Find used speakers and radios for the workshop. Featuring top brands from Bosch, Milwaukee, DeWalt, and Makita.",
  "Used Safety & Other": "Discover used safety equipment and other tools. Featuring top brands from 3M, Honeywell, Carhartt, and Klein Tools."
}


