export const breadcrumb = {
  'Used Tools': 'Products > All',
  'Used Power Tools': 'Products >  Power Tools',
  'Used Buffers & Polishers': 'Products > Power Tools > Buffers & Polishers',
  'Used Corded Drills': 'Products > Power Tools > Corded Drills',
  'Used Cordless Drills': 'Products > Power Tools > Cordless Drills',
  'Used Drill Presses': 'Products > Power Tools > Drill Presses',
  'Used Glue Guns': 'Products > Power Tools > Glue Guns',
  'Used Grinder': 'Products > Power Tools > Grinder',
  'Used Grout Mixers': 'Products > Power Tools > Grout Mixers',
  'Used Heat Guns': 'Products > Power Tools > Heat Guns',
  'Used Impact Wrenches': 'Products > Power Tools > Impact Wrenches',
  'Used Impact Driver': 'Products > Power Tools > Impact Driver',
  'Used Lathes': 'Products > Power Tools > Lathes',
  'Used Nail & Staple Guns': 'Products > Power Tools > Nail & Staple Guns',
  'Used Oscilating Tools': 'Products > Power Tools > Oscilating Tools',
  'Used Planers': 'Products > Power Tools > Planers',
  'Used Rotary Tools': 'Products > Power Tools > Rotary Tools',
  'Used Routers & Joiners': 'Products > Power Tools > Routers & Joiners',
  'Used Sanders': 'Products > Power Tools > Sanders',
  'Used Power-Actuated Tool': 'Products > Power Tools > Power-Actuated Tool',
  'Used Power Saws': 'Products > Power Tools > Power Saws',
  'Used Screw Guns & Screwdrivers': 'Products > Power Tools > Screw Guns & Screwdrivers',
  'Used Welding & Soldering Tools': 'Products > Power Tools > Welding & Soldering Tools',
  'Used Winches': 'Products > Hand Tools > Winches',
  'Used Outdoor Power Equipment': 'Products > Outdoor Power Equipment ',
  'Used Chainsaws': 'Products > Outdoor Power Equipment > Chainsaws',
  'Used Chippers, Shreaders & Mulchers':
    'Products > Outdoor Power Equipment > Chippers, Shreaders & Mulchers',
  'Used Edgers': 'Products > Outdoor Power Equipment > Edgers',
  'Used Generators': 'Products > Outdoor Power Equipment > Generators',
  'Used Grass Shears': 'Products > Outdoor Power Equipment > Grass Shears',
  'Used Hedge Trimmers': 'Products > Outdoor Power Equipment > Hedge Trimmers',
  'Used Hole Diggers & Augers': 'Products > Outdoor Power Equipment > Hole Diggers & Augers',
  'Used Lawn Mower': 'Products > Outdoor Power Equipment > Lawn Mower',
  'Used Leaf Blower': 'Products > Outdoor Power Equipment > Leaf Blower',
  'Used Log Splitter': 'Products > Outdoor Power Equipment > Log Splitter',
  'Used Pressure Washer': 'Products > Outdoor Power Equipment > Pressure Washer',
  'Used Snow Blower': 'Products > Outdoor Power Equipment > Snow Blower',
  'Used String Trimmers': 'Products > Outdoor Power Equipment > String Trimmers',
  'Used Sweepers': 'Products > Outdoor Power Equipment > Sweepers',
  'Used Shears & Nibblers': 'Products > Outdoor Power Equipment > Shears & Nibblers',
  'Used Tillers': 'Products > Outdoor Power Equipment > Tillers',
  'Used Hand Tools': 'Products > Hand Tools',
  'Used Caulking & Sealant Guns': 'Products > Hand Tools > Caulking & Sealant Guns',
  'Used Axes & Hatchets': 'Products > Hand Tools > Axes & Hatchets',
  'Used Brushes & Brooms': 'Products > Hand Tools > Brushes & Brooms',
  'Used Chisels': 'Products > Hand Tools > Chisels',
  'Used Champs & Vises': 'Products > Hand Tools > Champs & Vises',
  'Used Concrete Tools': 'Products > Hand Tools > Concrete Tools',
  'Used Cutting Tools': 'Products > Hand Tools > Cutting Tools',
  'Used Files': 'Products > Hand Tools > Files',
  'Used Hammers & Mallets': 'Products > Hand Tools > Hammers & Mallets',
  'Used Hand Tool Sets': 'Products > Hand Tools > Hand Tool Sets',
  'Used Knives & Blades': 'Products > Hand Tools > Knives & Blades',
  'Used Planes': 'Products > Hand Tools > Planes',
  'Used Pliers': 'Products > Hand Tools > Pliers',
  'Used Punches': 'Products > Hand Tools > Punches',
  'Used Putty Knives & Scrapers': 'Products > Hand Tools > Putty Knives & Scrapers',
  'Used Rivet Tools': 'Products > Hand Tools > Rivet Tools',
  'Used Saws': 'Products > Hand Tools > Saws',
  'Used Screwdriver & Nutdrivers': 'Products > Hand Tools > Screwdriver & Nutdrivers',
  'Used Shears & Snips': 'Products > Hand Tools > Shears & Snips',
  'Used Slab & Brick Lifters': 'Products > Hand Tools > Slab & Brick Lifters',
  'Used Staplers': 'Products > Hand Tools > Staplers',
  'Used Taps & Dies': 'Products > Hand Tools > Taps & Dies',
  'Used Trowels': 'Products > Hand Tools > Trowels',
  'Used Wire Brushes': 'Products > Hand Tools > Wire Brushes',
  'Used Wrenches': 'Products > Hand Tools > Wrenches',
  'Used Tool Storage': 'Products > Tool Storage',
  'Used Tool Boxes': 'Products > Tool Storage > Tool Boxes',
  'Used Tool Bags, Belts & Pouches': 'Products > Tool Storage > Tool Bags, Belts & Pouches',
  'Used Shelving & Racking Systems': 'Products > Tool Storage > Shelving & Racking Systems',
  'Used Tool Cabinets': 'Products > Tool Storage > Tool Cabinets',
  'Used Organizers': 'Products > Tool Storage > Organizers',
  'Used Wall Racks': 'Products > Tool Storage > Wall Racks',
  'Vintage Tools': 'Products > Vintage Tools',
  'Used Power Tool Accessories': 'Products > Power Tool Accessories',
  'Used Metalworking Tools': 'Products > Metalworking Tools',
  'Used Woodworking Tools': 'Products > Woodworking Tools',
  'Used Air Tools & Equipment': 'Products > Air Tools & Equipment',
  'Used Specialty Tools': 'Products > Specialty Tools',
  'Used Batteries': 'Products > Batteries',
  'Used Speakers & Radios': 'Products > Speakers & Radios',
  'Used Safety & Other': 'Products > Safety & Other',
};
